import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function TableEditIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M6.52539 6.17172H5.69206C5.25003 6.17172 4.82611 6.34732 4.51355 6.65988C4.20099 6.97244 4.02539 7.39636 4.02539 7.83839V15.3384C4.02539 15.7804 4.20099 16.2043 4.51355 16.5169C4.82611 16.8295 5.25003 17.0051 5.69206 17.0051H13.1921C13.6341 17.0051 14.058 16.8295 14.3706 16.5169C14.6831 16.2043 14.8587 15.7804 14.8587 15.3384V14.5051"
          stroke="#CC6699"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0247 4.50504L16.5247 7.00504M17.6789 5.82588C18.0071 5.49767 18.1915 5.05253 18.1915 4.58838C18.1915 4.12422 18.0071 3.67908 17.6789 3.35088C17.3507 3.02267 16.9056 2.83829 16.4414 2.83829C15.9773 2.83829 15.5321 3.02267 15.2039 3.35088L8.19141 10.3384V12.8384H10.6914L17.6789 5.82588Z"
          stroke="#CC6699"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

export default memo(TableEditIcon);