import * as api from 'utils/axios';
import * as endpoints from 'services/scan-wave/endpoints';
import { SCAN_API } from 'config';
import { IScanWave } from '_types/scanWave';

export const latestScanWave = () =>
  api.sendGet(SCAN_API + endpoints.LATEST_SCAN_WAVE, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export async function closeCurrentScanWave(): Promise<IScanWave> {
  const response = await api.sendPatch(SCAN_API + endpoints.CLOSE_CURRENT_SCAN_WAVE, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response as IScanWave;
}

export async function updateNameScanWave(scanWaveId: string, name: string): Promise<IScanWave> {
  const response = await api.sendPatch(
    SCAN_API + endpoints.BASE_SCAN_WAVE + `/${scanWaveId}/update-name?name=${name}`,
    { scanWaveId, name },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return response as IScanWave;
}

export async function updateReminderScanWave(
  scanWaveId: string,
  frequencyType: number
): Promise<IScanWave> {
  const response = await api.sendPatch(
    SCAN_API +
      endpoints.BASE_SCAN_WAVE +
      `/${scanWaveId}/update-reminder-frequency?frequencyType=${frequencyType}`,
    { scanWaveId, frequencyType },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return response as IScanWave;
}
