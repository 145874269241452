import * as api from 'utils/axios';
import * as endpoints from 'services/profile/endpoints';

export const profileInfo = (username: string) =>
  api.sendGet(endpoints.PROFILE_INFO + `/?username=${username}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
export const profileInfoFull = () =>
  api.sendGet(endpoints.PROFILE_INFO_FULL, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
export const updateProfileAvatar = (params: any) =>
  api.sendPatch(endpoints.PROFILE_AVATAR, params, {
    headers: { 'Content-Type': 'application/json' },
  });
export const deleteProfileAvatar = () =>
  api.sendDelete(endpoints.DELETE_PROFILE_AVATAR, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {},
  });
export const updateProfileInfo = (params: any) =>
  api.sendPatch(endpoints.UPDATE_PROFILE_INFO, params, {
    headers: { 'Content-Type': 'application/json' },
  });
export const updateOrganizationName = (params: any) =>
  api.sendPatch(endpoints.UPDATE_ORGANIZATION_NAME, params, {
    headers: { 'Content-Type': 'application/json' },
  });
export const updateJobTitle = (params: any) =>
  api.sendPatch(endpoints.UPDATE_PROFILE_JOB_TITLE, params, {
    headers: { 'Content-Type': 'application/json' },
  });
  export const updateOrganisationToStarter = () =>
    api.sendPatch(endpoints.UPDATE_ORGANISATION_TO_STARTER, {
      headers: { 'Content-Type': 'application/json' },
    });
