import { createSlice } from '@reduxjs/toolkit';
// @types
import { NavListProps } from 'components/nav-section/type';
import { TypeElementConstant } from '_types/elements';
//
import { dispatch } from '../store';
// nav config
import {
  navConfig,
  navConfigQuestionnaire,
  navConfigScorecard,
} from 'layouts/dashboard/navbar/NavConfig';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  navConfig: {
    subheader: string;
    items: NavListProps[];
  }[];
  elementConstant: TypeElementConstant | null;
  tabActive: string;
  showEmpy: boolean;
  chat: { query: string; response: string }[];
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  navConfig: navConfig,
  elementConstant: null,
  tabActive: '',
  showEmpy: false,
  chat: [],
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // UPDATE NAVCONFIG
    updateNavConfig(state, action) {
      state.navConfig = action.payload;
    },
    // UPDATE ELEMENT CONSTANT
    updateElementConstant(state, action) {
      state.elementConstant = action.payload;
    },
    // UPDATE TABS SETTING
    updateTabsSettings(state, action) {
      state.tabActive = action.payload;
    },
    // OPEN EMPY WIDGET
    showEmpy(state, action) {
      state.showEmpy = action.payload;
      if (action.payload === false) state.chat = [];
    },
    // UPDATE TABS SETTING
    updateChatConversation(state, action) {
      state.chat = action.payload;
    },
    // LOG OUT CLEAR REDUX TRIGGER
    logout(state) {},
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateNavConfig(nav: string) {
  return () => {
    try {
      if (nav === 'questionnaire') dispatch(slice.actions.updateNavConfig(navConfigQuestionnaire));
      if (nav === 'scorecard')
        dispatch(
          slice.actions.updateNavConfig(
            navConfigScorecard.map((item) => ({
              ...item,
              items: item.items.map((item) => ({
                ...item,
                caption2: item.title === 'Scan' ? '- Scan Completed' : '',
              })),
            }))
          )
        );
      if (nav === 'scorecard-closed')
        dispatch(
          slice.actions.updateNavConfig(
            navConfigScorecard.map((item) => ({
              ...item,
              items: item.items.map((item) => ({
                ...item,
                caption2: item.title === 'Scan' ? '- Scan Closed' : '',
              })),
            }))
          )
        );
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateElementConstant(elementConstant: TypeElementConstant) {
  return () => {
    try {
      dispatch(slice.actions.updateElementConstant(elementConstant));

      // Push event to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'currentCharacteristic',
        characteristic: elementConstant.name,
      });
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateTabsSettings(tab: string) {
  return () => {
    try {
      dispatch(slice.actions.updateTabsSettings(tab));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function clearLogout() {
  return () => {
    try {
      dispatch(slice.actions.logout());
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function setShowEmpy(show: boolean) {
  return () => dispatch(slice.actions.showEmpy(show));
}

// ----------------------------------------------------------------------

export function updateChatConversation(conversation: { query: string; response: string }[]) {
  return () => dispatch(slice.actions.updateChatConversation(conversation));
}

// ----------------------------------------------------------------------
